const { ControlWithFileDragAndDropSupport } = require('control-with-file-drag-and-drop-support')

class Dropzone extends ControlWithFileDragAndDropSupport {
  constructor(element, disabled=false) {
    super(element, disabled)
    this.dropzoneControl = this.element
    this.dropzoneControl.data('dropzoneControl', this)

    if (disabled) {
      this._setDisabled()
    }
    else {
      this.dropzone = this.element.find('.dropzone')
      this.form = this.element.closest('form')
      this.fileControl = this.dropzoneControl.find('input[type="file"]')
      this.clearButton = this.element.find('.clear-button')
      this.filled = false
      this.filePattern = this._getFilePattern()

      this._configureFallbackLink()
      this._configureFileControl()
      this._handleFormSubmit()
      this._configureClearButton()
    }
  }

  onDragEnter(evt) {
    this._setHovered(true)
  }

  onDragLeave(evt) {
    this._setHovered(false)
  }

  onFileDrop(evt, droppedFiles) {
    evt.stopPropagation()

    let file = this._getUploadedFile(droppedFiles)
    if (file) {
      this.setFile(file)
    }
  }

  setFile(file, triggerFileControlEvent=true) {
    this._setUploadedFile(file)
    this._setFilled(file)
    if (triggerFileControlEvent) {
      this.fileControl.trigger('change')
    }
  }

  clearFile(triggerFileControlEvent=true) {
    this._removeUploadedFile()
    this._setEmpty()
    if (triggerFileControlEvent) {
      this.fileControl.trigger('change')
    }
  }

  matchFile(file) {
    if (this.filePattern) {
      return file.name.match(this.filePattern)
    }

    return false
  }

  _setFilled(file) {
    this.filled = true
    this._setHovered(false)
    this.dropzoneControl
      .addClass('dropzone-filled')
      .find('.uploaded-file-name')
      .text(file.name)
  }

  _setEmpty() {
    this.filled = false
    this.dropzoneControl
      .removeClass('dropzone-filled')
  }

  _setUploadedFile(file) {
    let uploadedFiles = this.form.data('uploadedFiles') || {}
    let inputFileName = this.fileControl.attr('name')
    uploadedFiles[inputFileName] = file
    this.form.data('uploadedFiles', uploadedFiles)
  }

  _removeUploadedFile() {
    let uploadedFiles = this.form.data('uploadedFiles') || {}
    let inputFileName = this.fileControl.attr('name')
    this.fileControl.val("")
    delete uploadedFiles[inputFileName]
    this.form.data('uploadedFiles', uploadedFiles)
  }

  _getUploadedFile(droppedFiles) {
    if (droppedFiles.length == 1) {
      return droppedFiles[0]
    }

    if (!this.filePattern) {
      return droppedFiles[0]
    }

    file = droppedFiles.find(file => this.matchFile(file))
    if (!file) {
      return droppedFiles[0]
    }

    return file
  }

  _handleFormSubmit() {
    disableFormInputFiles = (form) => {
      const inputs = $('input[type="file"]:not([disabled])', form)
      inputs.each(function(index, input) {
        if (input.files.length > 0) return
        $(input).prop('disabled', true)
      })
    }

    enableFormInputFiles = (form, disabled) => {
      const inputs = $('input[type="file"]:disabled', form)
      inputs.each(function(index, input) {
        if (input.files.length > 0) return
        $(input).prop('disabled', false)
      })
    }

    this.form.unbind('ajax:before').on('ajax:before', function() {
      const url = $(this).attr('action')
      const token = $('meta[name="csrf-token"]').attr('content')

      disableFormInputFiles(this)

      const formData = new FormData(this)
      const uploadedFiles = $(this).data('uploadedFiles') || {}
      Object.keys(uploadedFiles).forEach(function(file) {
        formData.delete(file)
        formData.append(file, uploadedFiles[file])
      })

      const xhr = new XMLHttpRequest()
      xhr.open('POST', url, true)
      xhr.setRequestHeader('X-CSRF-Token', token)
      xhr.onreadystatechange = function() {
        if (this.readyState == XMLHttpRequest.DONE && this.status == 200) {
          eval(this.response)
        }
        enableFormInputFiles(this, false)
      }
      xhr.send(formData)

      return false
    })
  }

  _configureFallbackLink() {
    this.dropzoneControl.get(0).addEventListener('click', () => {
      this.fileControl.get(0).click()
    })
  }

  _configureFileControl() {
    this.fileControl.on('change', (evt) => {
      evt.preventDefault()

      let file = this.fileControl.get(0).files[0]
      if (file) {
        this.setFile(file, false)
      }
    })
  }

  _configureClearButton() {
    this.clearButton.on('click', (evt) => {
      evt.preventDefault()
      evt.stopPropagation()
      this.clearFile()
    })
  }

  _getFilePattern() {
    const pattern = this.dropzoneControl.data('filePattern')
    if (pattern) {
      return new RegExp(pattern, 'i')
    }
    else {
      return undefined
    }
  }

  _setDisabled() {
    this.dropzoneControl
      .addClass('dropzone-disabled')
  }

  _setHovered(hovered) {
    if (hovered) {
      this.dropzoneControl.addClass('dropzone-entered')
      if (this.filled) {
        this.dropzoneControl.addClass('dropzone-replace')
      }
    }
    else {
      this.dropzoneControl
        .removeClass('dropzone-entered')
        .removeClass('dropzone-replace')
    }
  }
}

module.exports = { Dropzone }
