import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  connect() {
    this.load();
    this._watch();
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  load() {
    return fetch(this.data.get('url'))
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html;
      });
  }

  _watch() {
    let received = data => {
      this.load();
      let systemPublication = JSON.parse(data);
      if (systemPublication.state == 'done') {
        Sqool.showNotification('info', this.data.get('published'));
      }
      if (systemPublication.state == 'failed') {
        Sqool.showNotification('alert', this.data.get('failedPublishing'));
      }
    };

    this.subscription = consumer.subscriptions.create({ channel: 'SystemPublicationChannel' }, { received });
  }

  publish(event) {
    event.preventDefault();
    this._confirm().then(() => {
      fetch(this.data.get('publishUrl'), { method: 'POST' }).then(() => {
        Sqool.showNotification('info', this.data.get('publishing'));
      });
    });
  }

  _confirm() {
    let message = this.data.get('confirm');
    return $.rails.confirm(message);
  }
}
