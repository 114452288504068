const { Control } = require('control')

class ControlWithDragAndDropSupport extends Control {
  constructor(element, disabled=false) {
    super(element)

    if (!disabled) {
      this.counter = 0
      this._handleDragEnterAndDragLeave()
      this._handleDrop()
    }
  }

  _handleDragEnterAndDragLeave() {
    this.element
      .on('dragenter', (evt) => {
        evt.preventDefault()
        this.counter++
        if ((typeof this.onDragEnter) === 'function') {
          if (this.dropAllowed(evt)) {
            this.onDragEnter(evt)
          }
        }
      })
      .on('dragleave', (evt) => {
        evt.preventDefault()
        this.counter--
        if (this.counter === 0) {
          if ((typeof this.onDragLeave) === 'function') {
            if (this.dropAllowed(evt)) {
              this.onDragLeave(evt)
            }
          }
        }
      })
  }

  _handleDrop() {
    this.element
      .on('dragover', (evt) => {
        evt.preventDefault()
        if ((typeof this.onDragOver) === 'function') {
          this.onDragOver(evt)
        }
      })
      .on('drop', (evt) => {
        evt.preventDefault()
        this.counter = 0
        if ((typeof this.onDrop) === 'function') {
          if (this.dropAllowed(evt)) {
            this.onDrop(evt)
          }
        }
      })
  }

  dropAllowed(evt) {
    return true
  }
}

module.exports = { ControlWithDragAndDropSupport }
