const { ControlWithFileDragAndDropSupport } = require('control-with-file-drag-and-drop-support')

class DropzoneContainer extends ControlWithFileDragAndDropSupport {
  constructor(element, disabled=false) {
    super(element, disabled)
    if (!disabled) {
      this.dropzones = this._getDropzonesWithFilePattern()
    }
  }

  onFileDrop(evt, droppedFiles) {
    if (this.dropzones.length > 0) {
      droppedFiles.forEach((file) => {
        this._assignToMatchingDropzone(file)
      })
    }
  }

  _getDropzonesWithFilePattern() {
    let controls = this.element.find('.dropzone-control')
      .toArray()
      .map(dropzone => $(dropzone).data('dropzoneControl'))
      .filter(dropzone => dropzone != undefined)
      .filter(dropzone => dropzone.filePattern != undefined)
    return controls
  }

  _assignToMatchingDropzone(file) {
    const matchingDropzone =
      this.dropzones.find(dropzone => dropzone.matchFile(file))

    if (matchingDropzone) {
      matchingDropzone.setFile(file)
    }
  }
}

module.exports = { DropzoneContainer }
