/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'jquery'
import 'jquery-ujs'
import 'chosen-js'
import '../../../node_modules/chosen-js/chosen.css'
import Noty from 'noty'
import '../../../node_modules/noty/lib/noty.css'
import '../../../node_modules/noty/lib/themes/sunset.css'
import '../../../node_modules/animate.css/animate.css'

export { Control } from 'control'
export { ControlWithDragAndDropSupport } from 'control-with-drag-and-drop-support'
export { ControlWithFileDragAndDropSupport } from 'control-with-file-drag-and-drop-support'
export { Dropzone } from 'dropzone'
export { DropzoneContainer } from 'dropzone-container'
export { Noty }

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
