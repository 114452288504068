import { Controller } from 'stimulus';

export default class extends Controller {
  get institutionLevelId() {
    return this.data.get('institutionLevelId');
  }

  get url() {
    return this.data.get('url');
  }

  connect() {
    fetch(this.url)
      .then(response => response.text())
      .then(html => (this.element.innerHTML = html));
  }
}
