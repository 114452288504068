const { ControlWithDragAndDropSupport } = require('control-with-drag-and-drop-support')

class ControlWithFileDragAndDropSupport extends ControlWithDragAndDropSupport {
  dropAllowed(evt) {
    return this._dropContainsFiles(evt)
  }

  onDrop(evt) {
    if ((typeof this.onFileDrop) === 'function') {
      this.onFileDrop(evt, this._getDroppedFiles(evt))
    }
  }

  _getDroppedFiles(evt) {
    const fileList = evt.originalEvent.dataTransfer.files
    var files = []
    for (var i = 0, len = fileList.length; i < len; i++) {
      files.push(fileList[i])
    }
    return files
  }

  _dropContainsFiles(evt) {
    const types = evt.originalEvent.dataTransfer.types
    if (types) {
      for (var i = 0; i < types.length; i++) {
        if (types[i] == "Files") {
          return true;
        }
      }
    }

    return false;
  }
}

module.exports = { ControlWithFileDragAndDropSupport }
