import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    this._update()
    if (this.isBusy) {
      this._watch()
    }
  }

  get isBusy() {
    return (this.data.get('busy') === 'true')
  }

  get imageTagSrc() {
    return this.data.get('imageTagSrc')
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  _watch() {
    let received = (data) => {
      let obj = JSON.parse(data)
      this.data.set('imageTagSrc', obj.image_tag_src)
      this.data.set('busy', obj.busy)
      this._update()
    }

    this.subscription = consumer.subscriptions.create({
      channel: "WebsiteChannel",
      id: this.data.get("id")
    }, { received })
  }

  _update() {
    if (this.isBusy) {
      this.element.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`
    } else {
      this.element.innerHTML = `<img src="${this.imageTagSrc}">`
    }
  }
}
