import { Controller } from 'stimulus';
import consumer from '../channels/consumer';
import { v4 as uuid } from 'uuid';

export default class extends Controller {
  get isBackgroundExport() {
    return this.data.get('isBackgroundExport');
  }

  get exportForm() {
    return $('#export_columns form');
  }

  get requestParams() {
    let serializedForm = this.exportForm.serialize();

    let params = new URLSearchParams(serializedForm);
    params.append('per_page', 1000000);

    return params;
  }

  get canDownload() {
    for (let [key, _] of this.requestParams.entries()) {
      if (key == 'columns[]') {
        return true;
      }
    }
    return false;
  }

  get downloadButton() {
    return $('#export-modal-download-button');
  }

  get busyExportingMessage() {
    return $('#export-modal-busy-exporting');
  }

  get exportFailureMessage() {
    return $('#export-modal-export-failure');
  }

  connect() {
    this._watchDialogClose();
    this._watchCheckboxes();
  }

  checkAll() {
    this._checkAllCheckboxes();
    this._updateDownloadButton();
  }

  download() {
    this.isBackgroundExport ? this._exportInBackground() : this._export();
  }

  _closeSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  _watchDialogClose() {
    $('#export_columns').on('hidden.bs.modal', _ => {
      this._resetUI();
      this._checkAllCheckboxes(false);
      this._closeSubscription();
    });
  }

  _resetUI() {
    this._hide(this.downloadButton);
    this._hide(this.exportFailureMessage);
    this._hide(this.busyExportingMessage);
  }

  _hide(component) {
    component.addClass('hidden');
  }

  _show(component) {
    component.removeClass('hidden');
  }

  _updateDownloadButton() {
    if (this.canDownload) {
      this._show(this.downloadButton);
    } else {
      this._hide(this.downloadButton);
    }
  }

  _watchCheckboxes() {
    this.exportForm.find('input[type="checkbox"]').each((_idx, element) => {
      $(element).change(e => this._updateDownloadButton());
    });
  }

  _checkAllCheckboxes(isChecked = true) {
    $('#export_columns')
      .find('.filters')
      .each((_idx, element) => {
        return $(element).prop('checked', isChecked);
      });
  }

  _appendRequestParams(url) {
    for (let [key, value] of this.requestParams.entries()) {
      url.searchParams.append(key, value);
    }

    if (location.search.length > 0) {
      const queryParams = new URLSearchParams(location.search);
      for (let [key, value] of queryParams.entries()) {
        url.searchParams.append(key, value);
      }
    }

    return url;
  }

  _export() {
    let url = new URL(`${location.origin}${location.pathname}.xls`);
    url = this._appendRequestParams(url);
    window.open(url, this.exportForm.attr('name'));
  }

  _exportInBackground() {
    this._resetUI();
    this._show(this.busyExportingMessage);

    let url = new URL(`${location.origin}${location.pathname}/export.js`);
    url = this._appendRequestParams(url);

    this.exportId = uuid();
    this._watchBackgrounWorker(this.exportId, data => this._onExportUpdate(data));

    url.searchParams.append('export_id', this.exportId);
    const requestData = url.search.slice(1);

    $.ajax({
      type: 'POST',
      url: '/background_exports',
      data: requestData,
      error: () => {
        this._resetUI();
        this._show(this.exportFailureMessage);
        this._show(this.downloadButton);
      },
    });
  }

  _watchBackgrounWorker(exportId, onUpdate) {
    this._closeSubscription();

    this.subscription = consumer.subscriptions.create(
      {
        channel: 'BackgroundExportChannel',
        export_id: exportId,
      },
      { received: onUpdate },
    );
  }

  _onExportUpdate(data) {
    let response = JSON.parse(data);
    this._resetUI();

    if (response.status == 'error') {
      this._show(this.exportFailureMessage);
      this._show(this.downloadButton);
      return;
    }

    this._show(this.downloadButton);
    window.location = new URL(`${location.origin}/background_exports/${this.exportId}/download`);
  }
}
